import React from "react";
import { IoIosArrowBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import "../css/AboutUs.css";

const AboutUs = () => {
  const navigate = useNavigate();
  return (
    <section className="about-section">
      <div className="about-topPart">
        <div onClick={() => navigate(-1)} className="backarrow-cont">
          <IoIosArrowBack className="backarrow" /> <p className="">Back</p>
        </div>
      </div>
      <div className="about-content">
        <div className="leftSide">
          <h1 className="">About Us</h1>
          <p className="">
            At Grav.id, we empower non-profits, social enterprises, and
            communities with secure, compliant digital solutions. Our platform
            enhances in-person interactions by providing safe online spaces for
            managing sensitive data and communications.{" "}
          </p>
          <p
            className="
            "
          >
            We prioritize user control and privacy, ensuring compliance with
            HIPAA, GDPR, CCPA, and other privacy laws. Our mission is to support
            organizations in building trust, improving efficiency, and
            demonstrating their impact, helping them thrive in their vital work.
          </p>
        </div>
        <div className="rightSide">
          <img src={require("../../assets/aboutpg.png")} alt="" className="" />
        </div>
      </div>
    </section>
  );
};

export default AboutUs;

import React from 'react'
import './LearnMore.scss'

export default function Governance() {
  return (
    <div className='box'>
        <h2 id="gravid-governance">GRAV.ID GOVERNANCE</h2>
        <h3 id="overview">Overview</h3>
        <h4 id="participation-contracts">Participation Contracts</h4>
        <p>The national legal frameworks of each member of the Grav.id
        ecosystem—such as those pertaining to employment and privacy—are
        followed by them. Making sure that businesses and individuals operating
        under various legal frameworks, regulations, and tactics may collaborate
        is the goal of legal interoperability. To guarantee operational
        consistency and legal clarity for each transaction, this calls for
        precise agreements to be signed by all parties involved.                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          </p>
        <p>In order for companies to engage in Network transactions, they must
        register their Decentralized Identifier (DID) with the Grav.id
        Registrar. Gav.id oversees and manages the registrar centrally.
        Organizations register their DID with the Network and submit their
        desired services to be offered only after they have been vetted by the
        registrar and have signed their participation agreement.</p>
        <h4 id="anti-trust-compliance">Anti-Trust Compliance</h4>
        <p>In order to bring interested companies together in a cooperative
        endeavor to create, maintain, safeguard, and advance specific
        initiatives outlined in the Bylaws “which are subject to periodic
        revision”Grav.id Foundation was established. Antitrust rules apply to
        these activities because competitors will be among the participants
        (referred to as "Members"). Even while it is entirely lawful for
        competitors to work together to accomplish pro-competitive objectives
        and efficiencies, it is crucial to recognize behavior that raises red
        flags in order to ensure that antitrust risks are minimized.</p>
        <p>The Foundation has made it clear that all of its operations, as well
        as those of any committees operating under its sponsorship, must be
        carried out strictly in compliance with all applicable international,
        state, federal, and local antitrust laws. The Establishment will not
        interfere with its Members' competitive business decisions, nor will it
        take or encourage any actions that could be interpreted as tending to
        restrict competition in contravention of antitrust laws.</p>
        <h4 id="board-of-directors">Board of Directors</h4>
        <p>The Board of Directors of the Foundation is in charge of running the
        organization. Each Foundation Member has one representative on the Board
        who was chosen by the entire membership. On every item presented to the
        Board for approval, each representative is allowed to cast one vote.
        They collaborate to decide on policies regarding the Grav.id governance
        as well as the incentives and payment scheme. During the first several
        months after the Network was established, the Founding Members kept a
        position on the board. Board members will be chosen by the entire
        membership in 2023 via the voting system.</p>
        <h4 id="voting-system">Voting System</h4>
        <p>The regulations that govern the conduct and determination of the
        results of elections and referendums are outlined in the Grav.id voting
        system. All parts of the voting process are governed by these
        regulations, including when elections are held, who is eligible to vote,
        who can run for office, how ballots are marked and cast, how they are
        tallied, and how the results of the vote are determined.</p>
        <h4 id="elections">Elections</h4>
        <p>Board members will be chosen annually by the entire membership as of
        2023. Every year, in the last month of the year, at a time determined by
        the acting Board of Directors, elections are held for the Board of
        Directors. The results of these elections will be implemented on January
        1st of the subsequent year.</p>
        <h4 id="candidates">Candidates</h4>
        <p>Organizations should notify the Board-appointed Voting Committee of
        their intention to nominate a delegate to the Board. The list of
        candidate groups will be made available to the general membership by the
        committee no later than 14 days before to the scheduled elections.</p>
        <h4 id="eligibility-to-vote">Eligibility to Vote</h4>
        <p>Voting is restricted to organizations that are Members of the
        Foundation at the time of the election.</p>
        <h4 id="how-votes-are-counted">How Votes Are Counted</h4>
        <p>One vote is equivalent to each credit that is in use. The day before
        the election, a member's reserve credits will determine how many credits
        they can use to cast a vote.</p>
        <h4 id="referendums">Referendums</h4>
        <p>The Board may occasionally choose to hold a referendum on significant
        Network-related decisions. The same voting procedure used for elections
        is also used for administering referendums.</p>
        <h3 id="committees">Committees</h3>
        <h3 id="compliance-committee">Compliance Committee</h3>
        <h4 id="membership">Membership</h4>
        <p>All interested stakeholders in the Grav.id Community are welcome to
        join, although preference will be given to those who have knowledge of
        the regulatory issues pertaining to HRM, education, data security,
        privacy, intellectual property, anti-trust, employment, and other
        relevant fields.</p>
        <h4 id="decision-making">Decision Making</h4>
        <p>Every committee within the Foundation aims to arrive at conclusions
        by consensus. Decisions will be put to a vote with the Board if there is
        no consensus.</p>
        <h4 id="chair">Chair</h4>
        <p>Ninh Tran (CEO)</p>
        <h3 id="product-committee">Product Committee</h3>
        <h4 id="charter">Charter</h4>
        <p>The Product Committee and Non Profit Board members are in charge of
        supporting the Board in carrying out its oversight duties with regard to
        the functional aspects of Grav.id, keeping track of the related
        Board-designated Technical Committee's activities, and advising the
        Board with regard to Grav.id's growth and advancement.</p>
        <h4 id="key-duties">Key Duties</h4>
        <p>Involve all stakeholders throughout the product lifecycle to expand
        the design process to a "whole product solution." Prior to risk areas
        endangering user experiences, identify, manage, and remedy them. Serve
        as the official means of reporting the entire state of the product to
        the Board. Be the last judge of when the product is prepared for
        shipment, including a formal sign-off procedure.</p>
        <h4 id="membership-1">Membership</h4>
        <p>All interested parties in the Grav.id Community are welcome to join,
        but we particularly seek out members who have experience with
        blockchain, self-sovereign digital identities, and use cases related to
        the global labor market, as well as with HR and EdTech workflows,
        products, and processes.</p>
        <h4 id="decision-making-1">Decision Making</h4>
        <p>Every committee within the Foundation aims to arrive at conclusions
        by consensus. Decisions will be put to a vote with the Board if there is
        no consensus.</p>
        <h4 id="chair-1">Chair</h4>
        <p>Beatrice Thelisma (CTO)</p>
        <h3 id="adoption-committee">Adoption Committee</h3>
        <h4 id="charter-1">Charter</h4>
        <p>The Adoption Committee is in charge of advising the Board on the
        measures and regulations required to encourage potential constituents to
        widely embrace Grav.id.</p>
        <h4 id="key-duties-1">Key Duties</h4>
        <p>Provide the Board with recommendations for tactics and initiatives to
        ensure that the various labor market ecosystem participants adopt the
        changes more quickly. Keep an eye on adoption and provide necessary
        interventions.</p>
        <h4 id="membership-2">Membership</h4>
        <p>All interested parties in the Grav.id Community are welcome to join,
        but membership is particularly accessible to those with experience in
        business development, marketing, strategy, and market segmentation and
        analysis.</p>
        <h4 id="decision-making-2">Decision Making</h4>
        <p>Every committee within the Foundation aims to arrive at conclusions
        by consensus. Decisions will be put to a vote with the Board if there is
        no consensus.</p>
        <h4 id="chair-2">Chair</h4>
        <p>James Willingham (CIO)</p>
        <h3 id="payment--rewards-committee">Payment &amp; Rewards Committee</h3>
        <h4 id="charter-2">Charter</h4>
        <p>By conducting research and advising the Board on token, fiscal, and
        monetary policies and actions, the Tokenomics Committee helps the Board
        carry out its oversight duties for Grav.id.</p>
        <h4 id="key-duties-2">Key Duties</h4>
        <p>Suggest financial and monetary plans and policies for the Network's
        token economy to the board. Keep an eye on the Network's economic
        activity and, if necessary, suggest monetary and fiscal actions.</p>
        <h4 id="membership-3">Membership</h4>
        <p>All interested parties in the Grav.id Community are welcome to join,
        but those with a background in finance or digital assets are highly
        encouraged.</p>
        <h4 id="decision-making-3">Decision Making</h4>
        <p>Every committee within the Foundation aims to arrive at conclusions
        by consensus. Decisions will be put to a vote with the Board if there is
        no consensus.</p>
        <h4 id="chair-3">Chair</h4>
        <p>Victory Bolade (PM)</p>
        <h3 id="standards-committee">Standards Committee</h3>
        <h4 id="charter-3">Charter</h4>
        <p>To ensure compatibility between issuers, verifiers, and holders
        taking part in the Network or any other verifiable credential networks,
        the Standards Committee is in charge of investigating, developing, and
        suggesting technical and data syntax and structure standards that impact
        the technical architecture of Grav.id.</p>
        <h4 id="key-duties-3">Key Duties</h4>
        <ul>
        <li>Determine the industry-standard forums that the Foundation and its
        stakeholders must participate in, and ensure that the Foundation is
        represented in these forums.</li>
        <li>Conduct research, compose, and disseminate articles outlining the
        Foundation's stance on standards-related matters.</li>
        <li>Communicate and consult on these matters with members and staff of
        the Foundation.</li>
        <li>Explain, debate, and defend these stances in forums that are
        pertinent to industry standards.</li>
        <li>Develop standards policy positions and recommend them to the Board
        for approval. Help the Foundation execute these policy recommendations
        in the Foundation's policies.</li>
        <li>Provide support and guidance to Grav.id tech stack engineers on
        standards compliance matters.</li>
        </ul>
        <h4 id="membership-4">Membership</h4>
        <p>Membership is open to all interested Grav.id Community stakeholders,
        especially those with expertise in standards development, particularly
        related to self-sovereign identity, education, HCM, privacy, security
        and blockchains. Decision Making Every committee within the Foundation
        aims to arrive at conclusions by consensus. Decisions will be put to a
        vote with the Board if there is no consensus.</p>
        <h4 id="chair-4">Chair</h4>
        <p>Justin Bieber (PM)</p>
        <h3 id="participation-agreements">Participation Agreements</h3>
        <p>Every member of the Grav.id ecosystem operates under the confines of
        their respective country's legal system (e.g., employment, privacy).
        Ensuring that entities and individuals functioning under disparate legal
        frameworks, rules, and tactics can collaborate is known as legal
        interoperability. For each transaction to guarantee operational
        consistency and legal clarity, the various parties must sign explicit
        agreements. Organizations must register their Decentralized Identifier
        (DID) with the Grav.id Registrar in order to participate in Network
        transactions. Grav.id Foundation® is in charge of centrally managing the
        registrant. The organization doesn't proceed with registering its DID
        with the Network, submitting the services it wants to offer on the
        Network, or providing the keys and credentials needed to access the
        Network until after it has been cleared by the registrar and has signed
        its participation agreement. Following submission of the DID revisions,
        the Grav.id Registrar verifies the information against the participation
        agreements and, if authorized, publishes a Provider Certification for
        that company. This certification, which can be verified, enumerates the
        services that this company is able to provide on the Network.</p>
        <p>Each role within the Network requires a separate Participation
        Agreement:</p>
        <ul>
        <li><a
        href="https://www.velocitynetwork.foundation/wp-content/uploads/2023/03/VNF-Organizational-User-Agreement.pdf">Organizational
        User</a> (Issuer, Relying Party)</li>
        <li><a
        href="https://velocitynetwork.foundation/wp-content/uploads/2022/07/VNF-Credential-Agent-Operator-Agreement-v1.23.pdf">Credential
        Agent</a></li>
        <li><a
        href="https://velocitynetwork.foundation/wp-content/uploads/2022/07/VNF-Steward-Agreement-v1.3.2.pdf">Node
        Operator</a></li>
        <li><a
        href="https://velocitynetwork.foundation/wp-content/uploads/2022/07/VNF-Wallet-Operator-Agreement-v1.1.pdf">Wallet
        Developer</a></li>
        </ul>
        <p>It is necessary for organizations to sign a separate Participation
        Agreement for each function they desire to play in the Network.</p>
        <p>One or more committees may be appointed by the board of directors.
        Each committee is made up of one or more Foundation directors who serve
        at the board of directors' request and have the legally assigned
        authority and responsibilities that go along with it.</p>
    </div>
  )
}

import React from 'react';
import "../css/TermService.css"
const SecurityPolicy = () => {
    return (
        <div  className=''>
            <div className='text_container'>
                <iframe title="vunerability disclosure" src="Grav.id - Vulnerability Disclosure Program.html"></iframe>
            </div>
        
        </div>
    );
};

export default SecurityPolicy;
import DirectAndPrivate from "./direct-and-private/DirectAndPrivate";
import Membership from "./membership/Membership";
import EasilyCreate from "./easily-create/EasilyCreate";
import TheFoundation from "./the-foundation/TheFoundation";
import TrustedAndSecured from "./trusted-and-secured/TrustedAndSecured";
import Main from "./main/Main";
import FoundationMembers from "./foundation-members/FoundationMembers";

function FoundationPage() {
  return (
  <>
    <Main />
    <TheFoundation />
    <TrustedAndSecured />
    <EasilyCreate />
    <DirectAndPrivate />
    <Membership />
    <FoundationMembers />
  </>

  );
}

export default FoundationPage;

import "./Community.scss";
import { FaArrowRight } from "react-icons/fa";

function Community() {
  return (
    <div className="ti_section">
      <div className="ti_container">
        <div className="ti_flex_row">
          <div className="ti_flex_col">
            <h2 className="ti_title">
            Build a Thriving 
            Community 
            </h2>
            <h5 className="ti_highlight">
            Building a thriving community is now simpler than ever. 
            Grav.id is designed for ease of use and growth. 
            Anyone can easily grow their community, paid memberships, events, 
            and online courses effortlessly with the support of our AI tools and professional community growth consultants.
            </h5>
            <button className="ti_button">
              <a href="https://app.grav.id/"><h5 className="ti_highlight1">Learn More</h5></a>
              <div className="ti_icon">
                <FaArrowRight />
              </div>
            </button>
          </div>
          <img
            className="ti_image"
            src={require("../../assets/landing.png")}
            alt="alt text"
          />
        </div>
      </div>
    </div>
  );
}

export default Community;

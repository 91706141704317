import First from "../../../../assets/oness.png";
import Second from "../../../../assets/twoos.png";


const data = [
  {
    title: "",
    description: "",
    urls: First,
  },
  {
    title: "",
    description: "",
    urls: Second,
  },
  // {
  //   title: "Grav.id Press Kit",
  //   description: "Being part of the wonderful initiatives driven by our community is truly an honor. These guidelines provide instructions on how to appropriately utilize the Grav.id (pronounced: 'gra-və-dē) name, logo, and wordmarks in diverse settings.",
  //   urls: Third,
  // },
  
];
export default data;

import React from 'react';
import "../css/TermService.css"
const TermServices = () => {
    return (
        <div  className=''>
            <div className='text_container'>
                <iframe title="terms of service" src="Grav.id - Terms of Use (Official).html"></iframe>
            </div>
        
        </div>
    );
};

export default TermServices;
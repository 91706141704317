import { HelpCenterItemModel } from "../models/HelpCenterItemModel";
import SignUpImage from "./../../../../assets/helper_center/sign_up.webp";
import BusinessAcountImage from "./../../../../assets/helper_center/business_account_creation.JPG";
import CreateCommunityImage from "./../../../../assets/helper_center/create_community.JPG";
import CreateServiceImage from "./../../../../assets/helper_center/create_service.JPG";
import AddAdminImage from "./../../../../assets/helper_center/add_admin.JPG";

const signUpItem = new HelpCenterItemModel(
  1,
  SignUpImage,
  "Grav.id Signup",
  ""
);
const GravIdCommunityPost = new HelpCenterItemModel(
  2,
  CreateCommunityImage,
  "Grav.id Community Posts",
  "How to Create a Community Post"
);
const BusinessAcount = new HelpCenterItemModel(
  3,
  BusinessAcountImage,
  "Grav.id Setup A Business Account",
  "How to Setup a Business Account"
);
const CreateService = new HelpCenterItemModel(
  4,
  CreateServiceImage,
  "Grav.id Create A Business Service",
  "How to Create a Business Service "
);
const AdminAccount = new HelpCenterItemModel(
  5,
  AddAdminImage,
  "Grav.id Add A Business Admin",
  "How to add a Business Admin"
);
export const helpCenterData = [
  signUpItem,
  GravIdCommunityPost,
  BusinessAcount,
  CreateService,
  AdminAccount,
];

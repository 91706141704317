import Main from "./main/Main";
import TrustedIdentity from "./trusted-identity/TrustedIdentity";
import FAQS from "./faqs/FAQS";
import Insights from "./data-security/Insights";
import Unlock from "./unlock-the-power/Unlock";

function HomePage() {
  return (
    <>
      <Main />
      <TrustedIdentity />
      <Unlock />
      <Insights />
      <FAQS />
    </>
  );
}

export default HomePage;
